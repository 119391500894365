const state = {
  homeActive:'home'
};

const mutations = {
 changeHomeActive(state,val){
  state.homeActive=val
 }
};

const actions = {
 
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
