import Vue from "vue";
import Router from "vue-router";
import { getToken } from "@/utils/auth";
Vue.use(Router);
// const routes = [
//   {
//     path: '/home',
//     name: 'home',
//     component: () => import('@/views/home/index.vue')
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]
export const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName:'home' */ "@/views/home/index.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/home/login.vue"),
  },
  {
    path: "/team",
    component: () => import("@/views/home/team.vue"),
  },
  {
    path: "/extend",
    component: () => import("@/views/home/extend.vue"),
  },
  {
    path: "/kjimei/:pathMath(.*)*",
    component: () => import("@/views/home/mixc.vue"),
  },
  {
    path: '/pre/:mallCode/:userId',
    component: () => import("@/views/home/middle.vue")
  }
];

const createRouter = () =>
  new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
router.beforeEach((to, from, next) => {
  const hasToken = getToken();
  if (to.path.indexOf("/userHome") >= 0 || to.path.indexOf("/person") >= 0) {
    if (hasToken) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
export default router;
