import {
  getToken,
  setToken,
  removeToken,
  getCookie,
  setCookie,
  removeCookie,
} from '@/utils/auth'
import {
  resetRouter
} from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getCookie('userInfo') ? JSON.parse(getCookie('userInfo')) : null,
  }
}


const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
    setCookie('userInfo', JSON.stringify(userInfo))
  },
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
