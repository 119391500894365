import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import { Toast, Popup  } from 'mint-ui';
import '../node_modules/mint-ui/lib/style.css';
import vueQr from 'vue-qr'
import './config/rem'

Vue.config.productionTip = false

Vue.component(Popup.name, Popup)
Vue.use(vueQr)

Vue.prototype.$toast = Toast
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
