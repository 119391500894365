import Cookies from 'js-cookie'

const TokenKey = 'kunjingcloud_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCookie(key) {
  return Cookies.get(key)
}

export function setCookie(key,data) {
  return Cookies.set(key, data)
}

export function removeCookie(key) {
  return Cookies.remove(key)
}

export function setLocalStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

export function removeLocalStorage(key) {
  window.localStorage.removeItem(key);
}


